// **  Initial State
const initialState = {
  userData: {},
  accessToken: ""
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      const { userData, accessToken } = action.data
      return {
        ...state,
        userData,
        accessToken
      }
    case "LOGOUT":
      return { ...state, userData: {}, accessToken: "" }
    default:
      return state
  }
}

export default authReducer
